@use 'sass/abstracts/variables';
@use 'sass/abstracts/mixins';


// .sc-fire-info {
//   height: 145px;
// }

.sc-left-message {
  margin-top: 8px;
  flex: 1;
}

.sc-right-map {
  margin-top: 7px;
  margin-left: 10px;
  flex: 2;
}

.sc-left {
  margin-top: 11px;
  flex: 1;
  max-width: 50% !important;
}

.sc-right {
  margin-top: 16px;
  flex: 1;
}

.sc-left-button-group {
  display: flex;
  height: 40px;
  justify-content: space-between;
}

.sc-message-board {
  height: 506px;

  .message-table {
    margin: 15px 12px 12px 13px;
  }

  .table-header {
    border-bottom: 2px solid variables.$black !important;
  }

  .table-title {
    font-weight: bold !important;
    font-size: 20px !important;
    line-height: 24px !important;
    padding-left: 17px;
    padding-bottom: 3px;
  }
}

/* .sc-message-board .ag-theme-quartz .ag-layout-auto-height .ag-center-cols-clipper{
  min-height: 0px !important;
} */

.sc-left-message .btn {
  height: 23px;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  padding-top: 4px;
  text-align: center;
  color: variables.$black;
}

.sc-message-board {

  .attach-btn-group {
    position: relative;
    display: flex;
    z-index: 9001;
  }

  .attach-loading {
    margin-top: 5px;
    position: absolute;
    right: 0px;
  }

  .btn-attach-item {
    height: 17px;
    background: variables.$white;
    border-radius: 5px;
    margin-right: 4px;
    margin-top: 3px;
    text-decoration: none;
    padding: 3px 2px 2px 6px;
    font-family: Roboto, variables.$main-font-family;
    font-size: 10px;
    line-height: 12px;
    color: variables.$black;
 
  }

  .btn-attach-item-del {
    font-family: Roboto, variables.$main-font-family;
    font-size: 10px;
    line-height: 12px;
    color: variables.$black;
    padding-left: 6px;
  }

  .attach-container {
    display: flex;
    flex-wrap: wrap;
    line-height: 22px;
    padding-left: 10px;
    background-color: variables.$grey-100;
    border: 1px solid variables.$grey-300;
    border-top-width: 0px;
  }

  .table-body {
    height: 378px !important;
    padding-right: 5px;
    overflow-y: scroll;
  }

  .add-comment {
    padding-top: 11px;
  }

  .ql-editor {
    min-height: 95px;
    font-family: Roboto, variables.$main-font-family;
    line-height: 14px;
  }

  .ql-toolbar {
    background-color: variables.$grey-100 !important;
    height: 35px !important;
    padding: 3px !important;
  }

  .ql-mention-list-container {
    width: 270px;
    border: 1px solid variables.$grey-100;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 12px 0 rgba(30, 30, 30, 0.08);
    z-index: 9002;
    overflow: auto;
    top: 30px !important;
  }

  .ql-mention-loading {
    line-height: 44px;
    padding: 0 20px;
    vertical-align: middle;
    font-size: 16px;
  }

  .ql-mention-list {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: auto;
  }

  .ql-mention-list-item {
    cursor: pointer;
    line-height: 14px;
    font-size: 12px;
    padding: 5px 20px;
    vertical-align: middle;
  }

  .sc-mention-mail {
    display: block;
    color: rgba(0, 0, 0, 0.55);
  }

  .ql-mention-list-item {
    &.disabled {
      cursor: auto;
    }

    &.selected {
      background-color: variables.$blue-faint;
      text-decoration: none;
    }
  }

  .mention {
    height: 24px;
    width: 65px;
    border-radius: 6px;
    color: variables.$blue-dark;
    padding: 3px 0;
    margin-right: 2px;
    user-select: all;

    > span {
      margin: 0 3px;
    }
  }

  .tox-toolbar__primary {
    background-color: variables.$grey-100 !important;
    height: 35px !important;
  }

  .ag-body-horizontal-scroll {
    display: none;
  }

  .message-item {
    padding-top: 11px;
    padding-bottom: 6px;
    border-top: solid 1px variables.$grey-300;

    &:last-child {
      border-bottom: solid 1px variables.$grey-300;
    }
  }

  .message-row {
    font-size: 12px;
    color: variables.$black;
    white-space: pre-wrap;
    word-break: break-word;
    line-height: 14px;
    padding-right: 35px;

    .mention-color {
      font-weight: bold;
      color: variables.$red;
    }
  }

  .float-end [class*=" bi-"]::before {
    vertical-align: 0px !important;
  }

  .message-row {
    .attach-group {
      margin-top: 6px;
      margin-bottom: 7px;
      height: 23px;
      display: flex;
    }

    .attach-link {
      margin-right: 17px;

      a {
        font-family: Roboto, variables.$main-font-family;
        font-size: 12px;
        line-height: 14px;
        text-decoration-line: underline;
        cursor: pointer;
        color: variables.$black;
     
      }

      i::before {
        font-size: 23px;
        height: 23px;
        color: variables.$black;
      }
    }
  }
}

.sc-weather {
  margin-top: 8px;
}

.sc-map {
  position: relative;
  height: 550px;

  .map-wrapper {
    height: 100%;
  }

  .map-actions {
    top: 12px;
    right: 12px;
  }
}

.sc-properties {
  margin: 12px 0 20px 0;

  .properties-table {
    margin: 22px 12px 17px 13px;
  }

  .ag-header-row {
    height: 50px !important;
  }
}

#scp_propertiesdatatable {
  height: 300px;
}

.sc-staff-comments {
  width: -webkit-fill-available;
  width: -moz-available;
  height: 99px;
  display: block;
  background-color: variables.$white;
  margin-left: 15px;
  resize: none;
  color: variables.$black;
  margin-top: 11px;
  margin-right: 17px;
}

.sc-staff-comments-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0px;
  margin-left: 15px;
}

.sc-staff-comments-rect {
  width: -webkit-fill-available;
  width: -moz-available;
  height: 2px;
  background-color: variables.$black;
  margin-left: 14px;
  margin-right: 13px;
}

.sc-header {
  display: flex;
  justify-content: space-between;

  .page-title {
    padding-left: 0;
  }
}

.sc-submittal-button {
  display: flex;
  align-items: center;
}

.sc-header-fire-name {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 30px;
  line-height: 35px;
  flex-grow: 1;
}

.sc-header-div {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-top: 15px;
  grid-template-columns: auto auto auto 20% 20%;
  gap: 0px;
}

.sc-header-left-column {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  margin: 0px;
  padding-right: 20px;
  text-align: right;
  flex-grow: .5;
}

.sc-header-right-column {
  font-size: 14px;
  line-height: 16px;
  margin: 0px;
  flex-grow: .5;
}

.sc-header-bold-text {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 11px;
}

.sc-header-normal-text {
  text-align: center;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 0px;
  text-overflow: ellipsis !important;
  overflow: hidden;
  display: block;
  white-space: nowrap;
}

.sc-header-caption {
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 30px;
  font-family: Roboto, variables.$main-font-family;
}

.sc-header-download-kmz {
  margin-top: 11px;
  text-decoration-line: underline;
  cursor: pointer;
  width: fit-content;
}

.sc-callout {
  .sltr-img {
    width: 28px;

    div.ms-Image {
      text-align: center;
    }

    img {
      display: inline;
    }
  }

  .sltr-item {
    font-size: 12px;

    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }

  .sltr-wrap {
    padding: 10px 17px;
  }

  .ms-Callout-main, .ms-FocusZone {
    background-color: transparent;
    border: none;
  }
}

.sc-map-expanded {
  .sc-map {
    height: 654px;
  }

  .sc-left-message {
    position: absolute;
    top: 801px !important;
    width: 50% !important;
    margin-top: 20px;
  }

  .sc-message-board {
    height: 582px !important;

    .table-body {
      height: 448px !important;
    }
  }

  .sc-right-map {
    margin-left: 0;
  }

  .sc-properties-weather {
    flex-direction: column !important;
    float: right !important;
    width: 50% !important;

    .sc-left {
      order: 2 !important;
      max-width: 100% !important;
      margin-left: 10px;
    }

    .sc-right {
      order: 1 !important;
      max-width: 100% !important;
      margin-left: 10px;
    }

    .sc-properties {
      margin-top: 0px !important;
      height: 620px;
    }
  }

  .row {
    clear: both;
  }
}

.sc-weather-title {
  width: 100%;
  width: -moz-available;

  /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available;

  /* Mozilla-based browsers will ignore this. */
  width: fill-available;
}

.sc-weather {
  flex-grow: 3;
  padding: 0 20px 0 20px;
  margin-top: 0;

  .properties-table {
    margin: 0px 12px 17px 13px;
  }

  .table-title {
    font-weight: bold !important;
    font-size: 20px !important;
    line-height: 24px !important;
    padding-left: 17px;
    padding-bottom: 1px;
  }

  .table-header {
    border-bottom: 2px solid variables.$black !important;
  }

  .ag-header-cell-label .ag-header-cell-text {
    font-weight: bold !important;
    font-size: 12px !important;
    line-height: 14px !important;
    color: variables.$black !important;
 
  }

  .ag-body-viewport {
    height: 62px !important;
  }

  .ag-body-horizontal-scroll {
    display: none;
  }

  .ag-cell {
    line-height: 14px !important;
    padding-top: 6px !important;
    font-size: 12px !important;
    color: variables.$black !important;
 
  }
}

.sc-dialog-map {
  width: 100% !important;
  height: 600px !important;
  margin: auto 29px;
  overflow: hidden;

  .sc-threat {
    margin: 0px !important;
    padding: 0px !important;
    width: 100% !important;
    display: flex;
  }

  .sc-threat-left {
    width: 244px;
    padding: 12px 14px 22px 13px;
    color: variables.$black;
    flex: none;
  }

  .sc-threat-right {
    flex: 1;
  }

  .sc-left-line {
    height: 1px;
    margin: 22.5px 31.5px 14.5px 0px;
    border-bottom: 1px solid variables.$grey-300;
  }

  .sc-upload-msg {
    height: 32.5px;
    margin: 0px 31.5px 14.5px 0px;
    border-bottom: 1px solid variables.$grey-300;
    line-height: 14px;
  }

  .sc-upload-error {
    color: variables.$red;
  }

  .sc-threat-left ul {
    padding-left: 1rem !important;
  }

  .sc-left-des ul > li {
    line-height: 19px !important;
  }

  .sc-left-draw {
    .left-side-header {
      margin-bottom: 7px;
    }

    ul {
      list-style-type: decimal;

      > li {
        line-height: 30px !important;
      }
    }
  }
}

.sc-threat-left {
  .left-side-header {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 9px;
  }

  .left-side-header-small {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
  }

  .left-side-content-large {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 14px;
  }

  .left-side-content-small {
    font-size: 14px;
    line-height: 17px;
  }

  .btn {
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    padding-top: 4px;
    text-align: center;
    color: variables.$black;
    width: 94px;
    height: 23px;
    border-radius: 5px;
  }
}

.sc-threat-require-dlg {
  max-width: 482px;
  height: 262px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  div[class*="topButton-"] {
    padding: 0px;
  }

  .ms-Dialog-title {
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    padding-top: 55px;
  }

  .ms-Dialog-header {
    margin: 0px;
    display: block;
  }

  .ms-Modal-scrollableContent div {
    overflow: hidden;
  }

  .ms-Dialog-actionsRight {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .ms-Dialog-action {
    margin: 0px 11px;

    &:last-child {
      margin-top: 15px;
    }
  }

  button {
    height: 45px;
    border-radius: 5px;
    border-width: 0px;
  }

  .ms-Button-label {
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    padding: 0px;
    margin: 0px;
  }

  .ms-Dialog-action {
    &:nth-child(1) {
      button {
        width: 350px;
        background: variables.$black;
      }

      .ms-Button-label {
        color: variables.$white;
      }
    }

    &:nth-child(2) button {
      width: 350px;
      background: variables.$wds-orange;
   
    }

    &:nth-child(3) button {
      width: 86px;
      background: variables.$grey-300;
    }
  }
}

.sc-dispatch-engine-dlg {
  max-width: 401px;
  min-width: 401px;
  height: 159px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  div[class^='topButton-'] {
    padding: 0px !important;
  }

  .ms-Dialog-title {
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    padding-top: 32px;
  }

  .ms-Dialog-header {
    margin: 0px;
    display: block;
  }

  .ms-Modal-scrollableContent div {
    overflow: hidden;
  }

  .ms-Dialog-actionsRight {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .ms-Dialog-action {
    margin: 0px 9px;

    button {
      width: 90px;
      height: 45px;
      border-radius: 5px;
      border-width: 0px;
    }
  }

  .ms-Button-label {
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    padding: 0px;
    margin: 0px;
  }

  .ms-Dialog-action {
    &:nth-child(1) {
      button {
        background: variables.$black;
      }

      .ms-Button-label {
        color: variables.$white;
      }
    }

    &:nth-child(2) button {
      background: variables.$wds-orange;
    }

    &:nth-child(3) button {
      background: variables.$grey-300;
    }
  }
}

.sc-threat-measurementInfo {
  position: absolute;
  right: 8px;
  top: 50px;
  z-index: 1;
  width: 186px;
  height: 103px;
  font-size: 16px;
  line-height: 19px;
  color: variables.$black;
  background: variables.$white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 14px 14px 14px 18px;
  white-space: nowrap;
  overflow: hidden;
}

#tpAzureMap {
  .light .azure-maps-drawtoolbar-button.active {
    background-color: variables.$black;
  }

  .azure-maps-drawtoolbar-button {
    &.draw-polygon.active {
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.82 12.018H.18V.607L7.6 5.362 11.821.098v11.92zm-10.64-1h9.64V2.944L7.818 6.689 1.18 2.435v8.583z' fill='%23F2B643'/%3E%3C/svg%3E");
    }

    &.edit-geometry.active {
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.058 12.032H1.79L6.6 2.65l2.183 4.483.748-.748L6.623.415.153 13.031h4.674l.231-1z' fill='%23F2B643'/%3E%3Cpath d='M11.482 6.838a1.248 1.248 0 0 0-1.766 0L5.82 10.735l-.531 2.297 2.297-.531 3.896-3.897a1.25 1.25 0 0 0 0-1.766zm-4.401 4.716l-.401.086.087-.4 3.624-3.624a.222.222 0 0 1 .314.314L7.08 11.554z' fill='%23F2B643'/%3E%3C/svg%3E");
    }

    &.erase-geometry.active {
      background-image: url("data:image/svg+xml,%3Csvg width='10' height='12' viewBox='0 0 10 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.75889 2.24859H9.00936V10.8682C9.00936 11.0256 8.97938 11.168 8.91942 11.3104C8.85946 11.4453 8.7845 11.5653 8.67957 11.6702C8.57464 11.7751 8.46221 11.8501 8.31979 11.91C8.17738 11.97 8.03497 12 7.87757 12H1.88132C1.72392 12 1.58151 11.97 1.4391 11.91C1.29669 11.8501 1.18426 11.7751 1.07932 11.6702C0.97439 11.5653 0.899437 11.4528 0.839475 11.3104C0.779512 11.1755 0.749531 11.0256 0.749531 10.8682V2.24859H0V1.49906H2.99812V0.749531C2.99812 0.644597 3.02061 0.547158 3.05809 0.457214C3.09556 0.367271 3.14803 0.284828 3.21549 0.21737C3.28295 0.149913 3.36539 0.0974404 3.45534 0.0599639C3.54528 0.0224873 3.64272 0 3.74766 0H5.99625C6.10118 0 6.19862 0.0224873 6.28857 0.0599639C6.37851 0.0974404 6.46096 0.149913 6.52842 0.21737C6.59587 0.284828 6.64834 0.367271 6.68582 0.457214C6.72329 0.547158 6.74578 0.644597 6.74578 0.749531V1.49906H9.7439V2.24859H9.75889ZM8.25983 2.24859H1.51405V10.8682C1.51405 10.9731 1.55153 11.0556 1.62648 11.1305C1.70144 11.2055 1.79138 11.243 1.88882 11.243H7.88507C7.99 11.243 8.07245 11.2055 8.1474 11.1305C8.22236 11.0556 8.25983 10.9656 8.25983 10.8682V2.24859ZM3.76265 9.7439H3.01311V3.74766H3.76265V9.7439ZM3.76265 1.49906H6.01124V0.749531H3.76265V1.49906ZM5.26171 9.7439H4.51218V3.74766H5.26171V9.7439ZM6.76077 9.7439H6.01124V3.74766H6.76077V9.7439Z' fill='%23F2B643'/%3E%3C/svg%3E%0A");
    }
  }
}

.sc-threat-right .ms-MessageBar {
  position: absolute;
  bottom: 0;
  z-index: 2;
}

.smoke-check-date-picker-wrapper {
  display: flex;
}

.smoke-check-date-picker {
  width: 200px;
}

.message-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.sc-clear-date {
  cursor: pointer;
  font-size: 24px;
  margin: 4px 0 0 5px;
}

.historic-toggle .is-checked button {
  background: variables.$wds-orange;

  &:hover {
    background: variables.$wds-hover-orange;
  }
}

.popup-collection-container {
  user-select: text;
}

.sc-workzone-alert-dlg {
  width: 456px;
  max-width: 456px;
  height: 244px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  div[class*="topButton-"] {
    padding: 0px;
  }

  .ms-Dialog-title {
    text-align: center;
    font-family: variables.$main-font-family;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: variables.$black;
    padding: 70px 25px 0 26px;
  }

  .ms-Dialog-header {
    margin: 0px;
    display: block;
  }

  .ms-Modal-scrollableContent div {
    overflow: hidden;
  }

  .ms-Dialog-actionsRight {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .ms-Dialog-action {
    margin: 0px 11px;

    &:last-child {
      margin-top: 31px;
    }
  }

  button {
    width: 144px;
    height: 42px;
    background: variables.$wds-orange;
    border: none;
    border-radius: 5px;
    box-sizing: border-box;

    &:hover, &:active {
      background-color: variables.$wds-orange;
    }
  }

  .ms-Button-label {
    text-align: center;
    font-family: variables.$main-font-family;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: variables.$black;
 
  }
}

.sc-fire-history {
  margin-top: 20px;
}

.new-threat-red {
  color: variables.$red;
  font-weight: bold;
}

.sc-kpi-box {
  margin-bottom: 20px;
  @include mixins.kpi-box(5px);

  .sc-kpi-white-box {
    display: flex;
    flex-direction: column;
    @include mixins.kpi-white-box(40px);

    .kpi-red {
      color: red;
      font-weight: bold;
    }
  }

  .sc-kpi-metric {
    @include mixins.kpi-metric();
  }

  .sc-kpi-value {
    display: flex;
    flex-direction: column;
    display:inline-block;
    @include mixins.kpi-value();
  }

  .sc-kpi-change{
    display: flex;
    flex-direction: column;
    @include mixins.kpi-change();
  }

  .sc-kpi-title {
    font-weight:700;
    font-size:18px;
    margin-bottom:2px;
    max-width:180px;
    text-align:center;
    overflow:visible;  
    overflow-wrap:break-word;
  }
}

.sc-last-update {
  @include mixins.kpi-last-update();
}

.sc-status {
  font-weight:700;
  font-size:18px;
  margin-bottom:2px;
  max-width:245px;
  text-align:center;
  overflow:visible;  
  overflow-wrap:break-word;

  .sc-status-red{
    color: red;
    font-weight: bold;
  }     
}

.sc-main-container{
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #ffffff;
  padding: 30px;
}
